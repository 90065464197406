// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-app-index-jsx": () => import("./../../../src/pages/app/index.jsx" /* webpackChunkName: "component---src-pages-app-index-jsx" */),
  "component---src-pages-app-login-jsx": () => import("./../../../src/pages/app/login.jsx" /* webpackChunkName: "component---src-pages-app-login-jsx" */),
  "component---src-pages-app-room-jsx": () => import("./../../../src/pages/app/room.jsx" /* webpackChunkName: "component---src-pages-app-room-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-room-jsx": () => import("./../../../src/templates/room.jsx" /* webpackChunkName: "component---src-templates-room-jsx" */)
}

